@charset "UTF-8";
/* グレー */
/* 薄いグレー */
/* 紫 */
/* 金 */
/* 薄紫 */
@import url("https://fonts.googleapis.com/css2?family=Noto+Serif+JP:wght@300;400;500&family=Vollkorn&display=swap");
/* smartphone font-size */
/* media quary */
/* flex */
/*!
 *
 * BurgerEditor StyleSheet Style Sample v2.21.0
 *
 */
@font-face {
  font-family: FontAwesome;
  src: url(../fonts/fontawesome-webfont.eot?v=4.6.3);
  src: url(../fonts/fontawesome-webfont.eot?#iefix&v=4.6.3) format("embedded-opentype"), url(../fonts/fontawesome-webfont.woff2?v=4.6.3) format("woff2"), url(../fonts/fontawesome-webfont.woff?v=4.6.3) format("woff"), url(../fonts/fontawesome-webfont.ttf?v=4.6.3) format("truetype"), url(../fonts/fontawesome-webfont.svg?v=4.6.3#fontawesomeregular) format("svg");
  font-weight: 400;
  font-style: normal;
}

[data-bgb] h2 {
  margin-bottom: 50px;
  padding: 10px 15px 16px 60px;
  border-bottom: 3px solid #a77d39;
  background: url(../img/common/icn_logo.svg) left 12px no-repeat;
  background-size: 37px 37px;
  color: #a77d39;
  font-size: 27px;
  font-weight: 400;
}

@media screen and (max-width: 599px) {
  [data-bgb] h2 {
    margin-top: 30px;
    margin-bottom: 30px;
    padding: 6px 0 8px 45px;
    background-size: 30px 30px;
    background-position: left 6px;
    font-size: 20px;
  }
}

[data-bgb] h2 a {
  color: #000;
  text-decoration: none;
}

[data-bgb] h2 a:hover {
  text-decoration: underline;
}

[data-bgb] h2:first-child {
  margin-top: 0;
}

[data-bgb] h3 {
  margin-bottom: 30px;
  padding: 10px 30px;
  background: linear-gradient(-90deg, #f5c0ca 0%, #dbdfee 100%);
  font-size: 21px;
  font-weight: 400;
}

@media screen and (max-width: 599px) {
  [data-bgb] h3 {
    margin-bottom: 20px;
    padding: 7px 20px;
    font-size: 17px;
  }
}

[data-bgb] h3:first-child {
  margin-top: 0;
}

[data-bgb] h4 {
  font-size: 1.3em;
  font-weight: 700;
  line-height: 1.2;
  margin: 1em 0;
  border: none;
  padding: 0.3em 0.2em;
  overflow: hidden;
  position: relative;
}

[data-bgb] h4:first-child {
  margin-top: 0;
}

[data-bgb] h4:after {
  position: absolute;
  bottom: 0.25em;
  left: 0;
  z-index: -1;
  content: '';
  width: 100%;
  height: 8px;
  background-color: #ddd;
  border-radius: 2px;
}

[data-bgb] h5 {
  font-size: 1.2em;
  font-weight: 700;
  line-height: 1.2;
  margin: 1em 0;
  padding: 0.3em 0;
  border-bottom: 1px dashed #999;
  overflow: hidden;
}

[data-bgb] h5:first-child {
  margin-top: 0;
}

[data-bgb] h6 {
  font-size: 1em;
  font-weight: 700;
  line-height: 1.2;
  margin: 1em 0;
  border: none;
  border-left: 3px solid #ccc;
  background: #eee;
  padding: 0.3em 0.5em;
  overflow: hidden;
}

[data-bgb] h6:first-child {
  margin-top: 0;
}

[data-bgb] p {
  margin: 1em 0;
}

[data-bgb] p:first-child {
  margin-top: 0;
}

[data-bgb] li {
  list-style: inherit;
  overflow: hidden;
}

[data-bgb] li:first-child {
  margin-top: 0;
}

[data-bgb] ul {
  margin: 1em 0;
}

[data-bgb] ul:first-child {
  margin-top: 0;
}

[data-bgb] ul ul {
  margin: 0 0 0 1.8em;
}

[data-bgb] ul ul:first-child {
  margin-top: 0;
}

[data-bgb] ul li {
  list-style-position: inside;
  list-style-type: disc;
  margin: 0 0 0.2em;
}

[data-bgb] ol {
  margin: 1em 0;
}

[data-bgb] ol:first-child {
  margin-top: 0;
}

[data-bgb] ol ol {
  margin: 0 0 0 2em;
}

[data-bgb] ol ol:first-child {
  margin-top: 0;
}

[data-bgb] ol li {
  margin: 0 0 0.2em;
  list-style-position: inside;
  list-style-type: decimal-leading-zero;
}

[data-bgb] blockquote {
  position: relative;
  margin: 1em 0;
  padding: 1px 2.5em;
  border-radius: 5px;
  background: none;
  border: 2px solid #ccc;
  overflow: hidden;
  color: #888;
  font-size: 0.9em;
}

[data-bgb] blockquote:first-child {
  margin-top: 0;
}

[data-bgb] blockquote:before {
  content: '“';
  top: 0.3em;
  left: 0.25em;
}

[data-bgb] blockquote:after,
[data-bgb] blockquote:before {
  display: block;
  font-family: serif;
  position: absolute;
  z-index: 0;
  color: #ccc;
  font-size: 3em;
}

[data-bgb] blockquote:after {
  content: '”';
  bottom: -0.3em;
  right: 0.25em;
}

[data-bgb] strong {
  font-weight: 700;
  font-style: normal;
  text-decoration: none;
}

[data-bgb] u {
  text-decoration: underline;
}

[data-bgb] s,
[data-bgb] u {
  font-weight: 400;
  font-style: normal;
}

[data-bgb] s {
  text-decoration: line-through;
}

[data-bgb] sub {
  vertical-align: sub;
}

[data-bgb] sub,
[data-bgb] sup {
  font-size: 0.8em;
  font-weight: 400;
  font-style: normal;
  text-decoration: none;
}

[data-bgb] sup {
  vertical-align: super;
}

[data-bgb] hr {
  width: 100%;
  height: 2px;
  color: #a77d39;
  background: currentColor;
  border-style: none;
  margin: 50px auto;
}

.bgt-box__caption {
  text-align: left;
  font-weight: 400;
  padding: 3px;
  background: none;
  font-size: 0.8em;
}

.bgt-btn {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  display: inline-block;
  vertical-align: middle;
  text-decoration: none;
}

.bgt-btn:active,
.bgt-btn:focus {
  -webkit-box-shadow: 0 0 10px 0 #0570c7, 0 0 10px 0 #fff;
  box-shadow: 0 0 10px 0 #0570c7, 0 0 10px 0 #fff;
}

.bgt-btn--link,
[data-bgt-button-kind='link'] .bgt-btn {
  position: relative;
  display: inline-block;
  width: 190px;
  padding: 14px 0 15px;
  background: #a77d39;
  color: #fff;
  font-size: 14px;
  text-align: center;
  text-decoration: none;
  -webkit-font-smoothing: antialiased;
}

.bgt-btn--link::before,
[data-bgt-button-kind='link'] .bgt-btn::before {
  position: absolute;
  left: 0;
  top: 50%;
  content: "";
  display: block;
  width: 28px;
  height: 1px;
  background: #fff;
}

.bgt-btn--link:hover,
[data-bgt-button-kind='link'] .bgt-btn:hover {
  opacity: 0.8;
}

.bgt-btn--em,
[data-bgt-button-kind='em'] .bgt-btn {
  font-weight: 700;
  color: #fff;
  border-color: #333;
  background: #333;
}

.bgt-btn--em:active,
.bgt-btn--em:focus,
.bgt-btn--em:hover,
[data-bgt-button-kind='em'] .bgt-btn:active,
[data-bgt-button-kind='em'] .bgt-btn:focus,
[data-bgt-button-kind='em'] .bgt-btn:hover {
  background: #555;
}

.bgt-btn--external,
[data-bgt-button-kind='external'] .bgt-btn {
  color: #333;
  border-color: #ccc;
  background: #fff;
}

.bgt-btn--external:active,
.bgt-btn--external:focus,
.bgt-btn--external:hover,
[data-bgt-button-kind='external'] .bgt-btn:active,
[data-bgt-button-kind='external'] .bgt-btn:focus,
[data-bgt-button-kind='external'] .bgt-btn:hover {
  background: #e9e9e9;
}

.bgt-btn--external:after,
[data-bgt-button-kind='external'] .bgt-btn:after {
  font-family: FontAwesome, serif;
  speak: none;
  font-style: normal;
  font-weight: 400;
  font-variant: normal;
  text-transform: none;
  text-rendering: auto;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: '\f08e';
  font-size: 1em;
  color: #999;
  vertical-align: middle;
}

.bgt-btn--back,
[data-bgt-button-kind='back'] .bgt-btn {
  color: #333;
  border-color: #ddd;
  background: #ddd;
}

.bgt-btn--back:active,
.bgt-btn--back:focus,
.bgt-btn--back:hover,
[data-bgt-button-kind='back'] .bgt-btn:active,
[data-bgt-button-kind='back'] .bgt-btn:focus,
[data-bgt-button-kind='back'] .bgt-btn:hover {
  background: #e9e9e9;
}

.bgt-btn--back span,
[data-bgt-button-kind='back'] .bgt-btn span {
  display: inline-block;
  vertical-align: middle;
}

.bgt-btn--back:before,
[data-bgt-button-kind='back'] .bgt-btn:before {
  font-family: FontAwesome, serif;
  speak: none;
  font-style: normal;
  font-weight: 400;
  font-variant: normal;
  text-transform: none;
  text-rendering: auto;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: '\f053';
  font-size: 1em;
  margin-top: -2px;
  display: inline-block;
  vertical-align: middle;
}

.bgt-hr {
  margin: 0 auto;
}

[data-bgt-hr-kind='dashed'] .bgt-hr {
  height: 0;
  background: none;
  border: none;
  border-bottom: 2px dashed;
}

.bgt-hr--bold,
[data-bgt-hr-kind='bold'] .bgt-hr {
  height: 5px;
}

.bgt-hr--narrow,
[data-bgt-hr-kind='narrow'] .bgt-hr {
  height: 1px;
}

.bgt-hr--short,
[data-bgt-hr-kind='short'] .bgt-hr {
  width: 60%;
  height: 1px;
}

.bgt-download-file__link {
  color: #333;
  text-align: left;
  font-size: 1.08em;
  font-weight: 700;
  display: inline-block;
  padding: 0.7em 1em;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  border: 1px solid #ccc;
  border-radius: 3px;
  background-color: #fff;
  line-height: 1;
  text-decoration: none;
}

.bgt-download-file__link:hover {
  background-color: #eee;
}

.bgt-download-file__link:active,
.bgt-download-file__link:focus {
  -webkit-box-shadow: 0 0 5px 1px #0570c7;
  box-shadow: 0 0 5px 1px #0570c7;
  outline: none;
}

.bgt-link__size {
  color: grey;
  font-size: 0.8em;
  font-weight: 400;
}

@media screen and (max-width: 768px) {
  .bgt-google-maps-link {
    margin-top: 10px;
    padding: 1em;
    text-decoration: none;
    color: #333;
    border: 1px solid #999;
    border-radius: 3px;
    text-align: center;
  }
  .bgt-google-maps-link span {
    display: inline-block;
    vertical-align: middle;
  }
  .bgt-google-maps-link:after {
    font-family: FontAwesome, serif;
    speak: none;
    font-style: normal;
    font-weight: 400;
    font-variant: normal;
    text-transform: none;
    text-rendering: auto;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    content: '\f08e';
    font-size: 1em;
    vertical-align: middle;
  }
}

[data-bgt='gallery'] .bgt-gallery {
  padding-top: 54%;
}

[data-bgt='gallery'] .bgt-gallery:hover [data-bgt='gallery'] .bgt-gallery-ctrl {
  opacity: 1;
}

[data-bgt='gallery'] .bgt-gallery__img img {
  -o-object-fit: cover;
  font-family: 'object-fit:cover';
  object-fit: cover;
}

[data-bgt='gallery'] .bgt-gallery__caption {
  color: #fff;
  background: rgba(0, 0, 0, 0.6);
  padding: 0.7em 1em;
  text-align: center;
}

[data-bgt='gallery'] .bgt-gallery-ctrl {
  top: 50%;
  opacity: 0;
  -webkit-transition: opacity 0.2s ease-in;
  transition: opacity 0.2s ease-in;
}

[data-bgt='gallery'] .bgt-gallery-ctrl__next,
[data-bgt='gallery'] .bgt-gallery-ctrl__prev {
  display: block;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: -20px 0 0;
  padding: 0;
  background: none;
  border: none;
  -webkit-transform-origin: center center;
  transform-origin: center center;
}

[data-bgt='gallery'] .bgt-gallery-ctrl__next:hover,
[data-bgt='gallery'] .bgt-gallery-ctrl__prev:hover {
  opacity: 0.5;
}

[data-bgt='gallery'] .bgt-gallery-ctrl__next:focus,
[data-bgt='gallery'] .bgt-gallery-ctrl__prev:focus {
  outline: none;
}

[data-bgt='gallery'] .bgt-gallery-ctrl__next span,
[data-bgt='gallery'] .bgt-gallery-ctrl__prev span {
  display: block;
  overflow: hidden;
  width: 0;
  height: 0;
}

[data-bgt='gallery'] .bgt-gallery-ctrl__next:after,
[data-bgt='gallery'] .bgt-gallery-ctrl__prev:after {
  content: '';
  display: block;
  width: 40px;
  height: 40px;
  border: solid #fff;
  border-width: 5px 5px 0 0;
}

[data-bgt='gallery'] .bgt-gallery-ctrl__prev {
  left: 20px;
  -webkit-transform: rotate(-135deg);
  transform: rotate(-135deg);
}

[data-bgt='gallery'] .bgt-gallery-ctrl__next {
  right: 20px;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}

[data-bgt='gallery'] [data-gallery-marker='thumbs'] + .bgt-gallery-marker {
  margin-top: 10px;
}

[data-bgt='gallery'] [data-gallery-marker='thumbs'] + .bgt-gallery-marker li {
  margin: 0 5px;
  width: 60px;
  height: 60px;
}

[data-bgt='gallery'] [data-gallery-marker='dot'] + .bgt-gallery-marker {
  margin-top: 10px;
}

[data-bgt='gallery'] [data-gallery-marker='dot'] + .bgt-gallery-marker li {
  margin: 0 2px;
  width: 10px;
  height: 10px;
  background-color: #999;
  border-radius: 100%;
}

[data-bgt='gallery'] [data-gallery-marker='dot'] + .bgt-gallery-marker li.current {
  background-color: #000;
}

.pc-only {
  display: block;
}

@media screen and (max-width: 768px) {
  .pc-only {
    display: none;
  }
}

.sp-only {
  display: none;
}

@media screen and (max-width: 768px) {
  .sp-only {
    display: block;
  }
}

.bgt-box__image-container {
  text-align: center;
}

.bgt-ckeditor-container a {
  text-decoration: underline;
}

.flex-block {
  display: flex;
  flex-wrap: wrap;
}

.flex-block .flex-grid {
  width: 23%;
  margin-right: 2.6666%;
  margin-bottom: 40px;
}

@media screen and (max-width: 599px) {
  .flex-block .flex-grid {
    width: 48%;
    margin-right: 4%;
    margin-bottom: 20px;
  }
}

@media screen and (min-width: 960px) {
  .flex-block .flex-grid:nth-child(4n) {
    margin-right: 0;
  }
}

@media screen and (max-width: 599px) {
  .flex-block .flex-grid:nth-child(2n) {
    margin-right: 0;
  }
}

.flex-block .flex-grid .bgt-image-container {
  margin-bottom: 10px;
}

.flex-block .flex-grid .bgt-ckeditor-container {
  line-height: 1.3;
}

@media screen and (max-width: 599px) {
  .btn-def [data-bgt-button-kind='link'] {
    text-align: center;
  }
}

.btn-def [data-bgt-button-kind='link'] a.bgt-btn {
  position: relative;
  display: inline-block;
  width: 190px;
  padding: 14px 0 15px;
  background: #a77d39;
  color: #fff;
  font-size: 14px;
  text-align: center;
  text-decoration: none;
  -webkit-font-smoothing: antialiased;
}

.btn-def [data-bgt-button-kind='link'] a.bgt-btn::before {
  position: absolute;
  left: 0;
  top: 50%;
  content: "";
  display: block;
  width: 28px;
  height: 1px;
  background: #fff;
}

.btn-def [data-bgt-button-kind='link'] a.bgt-btn:hover {
  opacity: 0.8;
}

.btn-gra [data-bgt-button-kind='link'] {
  text-align: center;
}

.btn-gra [data-bgt-button-kind='link'] a.bgt-btn {
  position: relative;
  display: inline-block;
  width: 280px;
  padding: 16px 0;
  background: #f5c0ca;
  background: linear-gradient(90deg, #f5c0ca 0%, #dbdfee 100%);
  color: #595757;
  font-size: 20px;
  text-decoration: none;
}

@media screen and (max-width: 599px) {
  .btn-gra [data-bgt-button-kind='link'] a.bgt-btn {
    font-size: 15px;
  }
}

.btn-gra [data-bgt-button-kind='link'] a.bgt-btn::before {
  position: absolute;
  left: 0;
  top: 50%;
  content: "";
  display: block;
  width: 36px;
  height: 1px;
  background: #595757;
}

.btn-gra [data-bgt-button-kind='link'] a.bgt-btn:hover {
  opacity: 0.8;
}

@media screen and (max-width: 599px) {
  .btn-white [data-bgt-button-kind='link'] {
    text-align: center;
  }
}

.btn-white [data-bgt-button-kind='link'] a.bgt-btn {
  position: relative;
  display: inline-block;
  width: 280px;
  padding: 18px 0 20px;
  border: 1px solid #a77d39;
  background: #fff;
  color: #595757;
  font-size: 20px;
  text-align: center;
  text-decoration: none;
}

@media screen and (max-width: 599px) {
  .btn-white [data-bgt-button-kind='link'] a.bgt-btn {
    font-size: 15px;
  }
}

.btn-white [data-bgt-button-kind='link'] a.bgt-btn::before {
  position: absolute;
  left: -1px;
  top: 50%;
  content: "";
  display: block;
  width: 36px;
  height: 1px;
  background: #595757;
}

.btn-white [data-bgt-button-kind='link'] a.bgt-btn:hover {
  background: #a77d39;
  color: #fff;
}

.btn-white [data-bgt-button-kind='link'] a.bgt-btn:hover::before {
  background: #fff;
}

.btn-ppl [data-bgt-button-kind='link'] a.bgt-btn {
  display: inline-block;
  width: 280px;
  padding: 10px 0 12px;
  background: #cf9dbf;
  color: #fff;
  font-size: 17px;
  font-weight: 500;
  text-align: center;
  text-decoration: none;
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
}

@media screen and (max-width: 599px) {
  .btn-ppl [data-bgt-button-kind='link'] a.bgt-btn {
    font-size: 15px;
  }
}

.btn-ppl [data-bgt-button-kind='link'] a.bgt-btn:hover {
  background: #E9E0EB;
  color: #595757;
}

[data-bgb] table {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  margin-bottom: 60px;
  border-top: 1px solid #B0B0B0;
}

[data-bgb] table tr {
  border-bottom: 1px solid #B0B0B0;
}

[data-bgb] table th {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  padding: 20px;
  background: #E9E0EB;
  color: #595757;
  font-size: 16px;
  text-align: left;
  vertical-align: top;
  white-space: nowrap;
}

@media screen and (max-width: 599px) {
  [data-bgb] table th {
    display: block;
    width: 100%;
    padding: 10px 15px;
  }
}

[data-bgb] table td {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  padding: 20px;
  font-size: 15px;
  line-height: 1.5;
}

@media screen and (max-width: 599px) {
  [data-bgb] table td {
    display: block;
    width: 100%;
    padding: 15px 0;
    font-size: 15px;
  }
}

[data-bgb=button2] .bgt-grid:first-child, [data-bgb=download-file2] .bgt-grid:first-child, [data-bgb=image2] .bgt-grid:first-child, [data-bgb=image-link2] .bgt-grid:first-child, [data-bgb=image-link-text2] .bgt-grid:first-child, [data-bgb=image-text2] .bgt-grid:first-child, [data-bgb=trimmed-image2] .bgt-grid:first-child, [data-bgb=trimmed-image-link2] .bgt-grid:first-child, [data-bgb=wysiwyg2] .bgt-grid:first-child {
  padding-right: 30px;
}

@media screen and (max-width: 599px) {
  [data-bgb=button2] .bgt-grid:first-child, [data-bgb=download-file2] .bgt-grid:first-child, [data-bgb=image2] .bgt-grid:first-child, [data-bgb=image-link2] .bgt-grid:first-child, [data-bgb=image-link-text2] .bgt-grid:first-child, [data-bgb=image-text2] .bgt-grid:first-child, [data-bgb=trimmed-image2] .bgt-grid:first-child, [data-bgb=trimmed-image-link2] .bgt-grid:first-child, [data-bgb=wysiwyg2] .bgt-grid:first-child {
    margin-bottom: 60px;
    padding-right: 0;
  }
}

[data-bgb=button2] .bgt-grid:nth-child(2), [data-bgb=download-file2] .bgt-grid:nth-child(2), [data-bgb=image2] .bgt-grid:nth-child(2), [data-bgb=image-link2] .bgt-grid:nth-child(2), [data-bgb=image-link-text2] .bgt-grid:nth-child(2), [data-bgb=image-text2] .bgt-grid:nth-child(2), [data-bgb=trimmed-image2] .bgt-grid:nth-child(2), [data-bgb=trimmed-image-link2] .bgt-grid:nth-child(2), [data-bgb=wysiwyg2] .bgt-grid:nth-child(2) {
  padding-left: 30px;
}

@media screen and (max-width: 599px) {
  [data-bgb=button2] .bgt-grid:nth-child(2), [data-bgb=download-file2] .bgt-grid:nth-child(2), [data-bgb=image2] .bgt-grid:nth-child(2), [data-bgb=image-link2] .bgt-grid:nth-child(2), [data-bgb=image-link-text2] .bgt-grid:nth-child(2), [data-bgb=image-text2] .bgt-grid:nth-child(2), [data-bgb=trimmed-image2] .bgt-grid:nth-child(2), [data-bgb=trimmed-image-link2] .bgt-grid:nth-child(2), [data-bgb=wysiwyg2] .bgt-grid:nth-child(2) {
    margin-bottom: 60px;
    padding-left: 0;
  }
}

/*--------------------------------------------------------
  ボタン
----------------------------------------------------------*/
@media screen and (max-width: 599px) {
  .btn-def {
    text-align: center;
  }
}

.btn-def a {
  position: relative;
  display: inline-block;
  width: 190px;
  padding: 14px 0 15px;
  background: #a77d39;
  color: #fff;
  font-size: 14px;
  text-align: center;
  text-decoration: none;
  -webkit-font-smoothing: antialiased;
}

.btn-def a::before {
  position: absolute;
  left: 0;
  top: 50%;
  content: "";
  display: block;
  width: 28px;
  height: 1px;
  background: #fff;
}

.btn-def a:hover {
  opacity: 0.8;
}

.btn-def-big {
  text-align: center;
}

.btn-def-big a {
  position: relative;
  display: inline-block;
  width: 400px;
  padding: 14px 0 15px;
  background: #a77d39;
  color: #fff;
  font-size: 24px;
  text-align: center;
  text-decoration: none;
  -webkit-font-smoothing: antialiased;
}

@media screen and (max-width: 599px) {
  .btn-def-big a {
    width: 100%;
    font-size: 18px;
  }
}

.btn-def-big a:hover {
  opacity: 0.8;
}

.btn-gra {
  text-align: center;
}

.btn-gra a {
  position: relative;
  display: inline-block;
  width: 280px;
  padding: 16px 0;
  background: #f5c0ca;
  background: linear-gradient(90deg, #f5c0ca 0%, #dbdfee 100%);
  font-size: 20px;
  text-decoration: none;
}

@media screen and (max-width: 599px) {
  .btn-gra a {
    font-size: 15px;
  }
}

.btn-gra a::before {
  position: absolute;
  left: 0;
  top: 50%;
  content: "";
  display: block;
  width: 36px;
  height: 1px;
  background: #595757;
}

.btn-gra a:hover {
  opacity: 0.8;
}

@media screen and (max-width: 599px) {
  .btn-white {
    text-align: center;
  }
}

.btn-white a {
  position: relative;
  display: inline-block;
  width: 280px;
  padding: 18px 0 20px;
  background: #fff;
  font-size: 20px;
  text-align: center;
  text-decoration: none;
}

@media screen and (max-width: 599px) {
  .btn-white a {
    font-size: 15px;
  }
}

.btn-white a::before {
  position: absolute;
  left: 0;
  top: 50%;
  content: "";
  display: block;
  width: 36px;
  height: 1px;
  background: #595757;
}

.btn-white a:hover {
  opacity: 0.8;
}

.btn-ppl a {
  display: inline-block;
  width: 280px;
  padding: 10px 0 12px;
  background: #cf9dbf;
  color: #fff;
  font-size: 17px;
  font-weight: 500;
  text-align: center;
  text-decoration: none;
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
}

@media screen and (max-width: 599px) {
  .btn-ppl a {
    font-size: 15px;
  }
}

.btn-ppl a:hover {
  background: #E9E0EB;
  color: #595757;
}

.btn-ig a {
  display: inline-block;
  width: 280px;
  padding: 10px 0 12px;
  border-radius: 8px;
  background: #fff url(../img/common/icn_ig.svg) 50px center no-repeat;
  background-size: 23px 23px;
  color: #595757;
  font-size: 17px;
  font-weight: 500;
  text-align: center;
  text-decoration: none;
  -webkit-font-smoothing: antialiased;
}

@media screen and (max-width: 599px) {
  .btn-ig a {
    font-size: 15px;
  }
}
