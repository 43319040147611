.bgt-box__image-container {
  text-align: center;
}

.bgt-ckeditor-container {
  a {
    text-decoration: underline;
  }
}

.flex-block {
  display: flex;
  flex-wrap: wrap;
  .flex-grid {
    width: 23%;
    margin-right: 2.6666%;
    margin-bottom: 40px;
    @include mq(sp) {
      width: 48%;
      margin-right: 4%;
      margin-bottom: 20px;
    }
    &:nth-child(4n) {
      @include mq(pc) {
        margin-right: 0;
      }
    }
    &:nth-child(2n) {
      @include mq(sp) {
        margin-right: 0;
      }
    }
    .bgt-image-container {
      margin-bottom: 10px;
    }
    .bgt-ckeditor-container {
      line-height: 1.3;
    }
  }
}

.btn-def [data-bgt-button-kind='link'] {
    @include mq(sp) {
        text-align: center;
    }
    a.bgt-btn {
        position: relative;
        display: inline-block;
        width: 190px;
        padding: 14px 0 15px;
        background: $sub;
        color: #fff;
        font-size: 14px;
        text-align: center;
        text-decoration: none;
        -webkit-font-smoothing: antialiased;
        &::before {
            position: absolute;
            left: 0;
            top: 50%;
            content: "";
            display: block;
            width: 28px;
            height: 1px;
            background: #fff;
        }
        &:hover {
            opacity: 0.8;
        }
    }
}

.btn-gra [data-bgt-button-kind='link'] {
    text-align: center;
    a.bgt-btn  {
        position: relative;
        display: inline-block;
        width: 280px;
        padding: 16px 0;
        background: rgb(245,192,202);
        background: linear-gradient(90deg, rgba(245,192,202,1) 0%, rgba(219,223,238,1) 100%);
        color: $black;
        font-size: 20px;
        text-decoration: none;
        @include mq(sp) {
            font-size: 15px;
        }
        &::before {
            position: absolute;
            left: 0;
            top: 50%;
            content: "";
            display: block;
            width: 36px;
            height: 1px;
            background: $black;
        }
        &:hover {
            opacity: 0.8;
        }
    }
}

.btn-white [data-bgt-button-kind='link'] {
    @include mq(sp) {
        text-align: center;
    }
    a.bgt-btn {
        position: relative;
        display: inline-block;
        width: 280px;
        padding: 18px 0 20px;
        border: 1px solid $sub;
        background: #fff;
        color: $black;
        font-size: 20px;
        text-align: center;
        text-decoration: none;
        @include mq(sp) {
            font-size: 15px;
        }
        &::before {
            position: absolute;
            left: -1px;
            top: 50%;
            content: "";
            display: block;
            width: 36px;
            height: 1px;
            background: $black;
        }
        &:hover {
            background: $sub;
            color: #fff;
            &::before {
              background: #fff;
            }
        }
    }
}

.btn-ppl [data-bgt-button-kind='link'] {
    a.bgt-btn {
        display: inline-block;
        width: 280px;
        padding: 10px 0 12px;
        background: $main;
        color: #fff;
        font-size: 17px;
        font-weight: 500;
        text-align: center;
        text-decoration: none;
        box-sizing: border-box;
        -webkit-font-smoothing: antialiased;
        @include mq(sp) {
            font-size: 15px;
        }
        &:hover {
            background: $light-main;
            color: $black;
        }
    }
}

[data-bgb] {
  table {
    @include box-sizing(border-box);
    margin-bottom: 60px;
    border-top: 1px solid $gray;
    tr {
      border-bottom: 1px solid $gray;
    }
    th {
      @include box-sizing(border-box);
      padding: 20px;
      background: $light-main;
      color: $black;
      font-size: 16px;
      text-align: left;
      vertical-align: top;
      white-space: nowrap;
      @include mq(sp) {
        display: block;
        width: 100%;
        padding: 10px 15px;
      }
    }
    td {
      @include box-sizing(border-box);
      padding: 20px;
      font-size: 15px;
      line-height: 1.5;
      @include mq(sp) {
        display: block;
        width: 100%;
        padding: 15px 0;
        font-size: 15px;
      }
    }
  }
}


[data-bgb=button2] .bgt-grid:first-child, [data-bgb=download-file2] .bgt-grid:first-child, [data-bgb=image2] .bgt-grid:first-child, [data-bgb=image-link2] .bgt-grid:first-child, [data-bgb=image-link-text2] .bgt-grid:first-child, [data-bgb=image-text2] .bgt-grid:first-child, [data-bgb=trimmed-image2] .bgt-grid:first-child, [data-bgb=trimmed-image-link2] .bgt-grid:first-child, [data-bgb=wysiwyg2] .bgt-grid:first-child {
    padding-right: 30px;
    @include mq(sp) {
      margin-bottom: 60px;
      padding-right: 0;
    }
}
[data-bgb=button2] .bgt-grid:nth-child(2), [data-bgb=download-file2] .bgt-grid:nth-child(2), [data-bgb=image2] .bgt-grid:nth-child(2), [data-bgb=image-link2] .bgt-grid:nth-child(2), [data-bgb=image-link-text2] .bgt-grid:nth-child(2), [data-bgb=image-text2] .bgt-grid:nth-child(2), [data-bgb=trimmed-image2] .bgt-grid:nth-child(2), [data-bgb=trimmed-image-link2] .bgt-grid:nth-child(2), [data-bgb=wysiwyg2] .bgt-grid:nth-child(2) {
    padding-left: 30px;
    @include mq(sp) {
      margin-bottom: 60px;
      padding-left: 0;
    }
}

/*--------------------------------------------------------
  ボタン
----------------------------------------------------------*/

.btn-def {
    @include mq(sp) {
        text-align: center;
    }
    a {
        position: relative;
        display: inline-block;
        width: 190px;
        padding: 14px 0 15px;
        background: $sub;
        color: #fff;
        font-size: 14px;
        text-align: center;
        text-decoration: none;
        -webkit-font-smoothing: antialiased;
        &::before {
            position: absolute;
            left: 0;
            top: 50%;
            content: "";
            display: block;
            width: 28px;
            height: 1px;
            background: #fff;
        }
        &:hover {
            opacity: 0.8;
        }
    }
}

.btn-def-big {
    text-align: center;
    a {
        position: relative;
        display: inline-block;
        width: 400px;
        padding: 14px 0 15px;
        background: $sub;
        color: #fff;
        font-size: 24px;
        text-align: center;
        text-decoration: none;
        -webkit-font-smoothing: antialiased;
        @include mq(sp) {
            width: 100%;
            font-size: 18px;
        }
        &:hover {
            opacity: 0.8;
        }
    }
}

.btn-gra {
    text-align: center;
    a {
        position: relative;
        display: inline-block;
        width: 280px;
        padding: 16px 0;
        background: rgb(245,192,202);
        background: linear-gradient(90deg, rgba(245,192,202,1) 0%, rgba(219,223,238,1) 100%);
        font-size: 20px;
        text-decoration: none;
        @include mq(sp) {
            font-size: 15px;
        }
        &::before {
            position: absolute;
            left: 0;
            top: 50%;
            content: "";
            display: block;
            width: 36px;
            height: 1px;
            background: $black;
        }
        &:hover {
            opacity: 0.8;
        }
    }
}

.btn-white {
    @include mq(sp) {
        text-align: center;
    }
    a {
        position: relative;
        display: inline-block;
        width: 280px;
        padding: 18px 0 20px;
        background: #fff;
        font-size: 20px;
        text-align: center;
        text-decoration: none;
        @include mq(sp) {
            font-size: 15px;
        }
        &::before {
            position: absolute;
            left: 0;
            top: 50%;
            content: "";
            display: block;
            width: 36px;
            height: 1px;
            background: $black;
        }
        &:hover {
            opacity: 0.8;
        }
    }
}

.btn-ppl {
    a {
        display: inline-block;
        width: 280px;
        padding: 10px 0 12px;
        background: $main;
        color: #fff;
        font-size: 17px;
        font-weight: 500;
        text-align: center;
        text-decoration: none;
        box-sizing: border-box;
        -webkit-font-smoothing: antialiased;
        @include mq(sp) {
            font-size: 15px;
        }
        &:hover {
            background: $light-main;
            color: $black;
        }
    }
}

.btn-ig {
    a {
        display: inline-block;
        width: 280px;
        padding: 10px 0 12px;
        border-radius: 8px;
        background: #fff url(../img/common/icn_ig.svg) 50px center no-repeat;
        background-size: 23px 23px;
        color: $black;
        font-size: 17px;
        font-weight: 500;
        text-align: center;
        text-decoration: none;
        -webkit-font-smoothing: antialiased;
        @include mq(sp) {
            font-size: 15px;
        }
        &:hover {
        }
    }
}